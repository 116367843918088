.secondary-header.au {
    & .secondary-header-container {
        width: 100%;
        height: 100px;
        border-bottom: 1px solid $Inactive-Gray;
        margin: auto;
        // padding: 0 9% 0 8.7%;
        display: flex;
        max-width: inherit;
        padding: 0px 150px;
        @media only screen and (min-device-width: 1021px) and (max-device-width: 1099px) {
            padding: 0 6.2% 0 4.7%;
        }
        @media only screen and (min-device-width: 1200px) and (max-device-width: 1499px) {
            // padding: 0 9% 0 9%;
        }
        @media #{$breakpoint-below-tablet} {
            max-width: 375px;
            padding: 0 20px;
            height: 45px;
        }
        .back-button-container {
            margin: auto 0;
            width: auto;
            cursor: pointer;
            @media #{$breakpoint-below-tablet} {
                width: 56px;
            }
            .button-contained {
                @media #{$breakpoint-above-tablet} {
                 margin-top: 1px;
                }
            }
            .back-button {
                font-family: $National2Medium;
                background-color: transparent;
                outline: none;
                border: none;
                width: fit-content;
                @media #{$breakpoint-above-tablet} {
                    border: 1.5px solid $Brand-Black;
                    border-radius: 200px;
                    padding: 10px 40px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $Brand-Black;
                    margin-right: auto;

                    &:hover {
                        background-color: $Brand-Black;
                        color: $white;
                    }

                    &:active {
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
                            $Brand-Black;
                        color: $white;
                    }

                    &:focus {
                        background-color: $Brand-Black;
                        color: $white;
                        outline: 2px solid #2e4abd;
                        border-radius: 200px;
                    }
                }
                .back-text {
                    @media #{$breakpoint-below-tablet} {
                        display: none;
                    }
                }
                .back-icon {
                    @media #{$breakpoint-above-tablet} {
                        display: none;
                    }
                    height: 24px;
                }
            }
            .payment-back-btn {
                @media #{$breakpoint-below-tablet} {
                    display: none;
                }
            }
            .payment-help-container {
                width: fit-content;
                .help-icon {
                    @media #{$breakpoint-above-tablet} {
                        display: none;
                    }
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }
        }
        .kfc-header-container {
            margin: auto;
            cursor: pointer;
            padding-right: 30px;
            .kfc-header-icon {
                @media #{$breakpoint-below-tablet} {
                    display: none;
                }
            }
            .text-icon {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 22px;
                text-align: center;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $Brand-Black;
                @media #{$breakpoint-above-tablet} {
                    display: none;
                }
            }
        }
        .right-nav-container {
            width: auto;
            margin: auto 0;
            display: flex;
            justify-content: space-between;

            @media #{$breakpoint-below-tablet} {
                width: 56px;
            }

            .need-help-container {
                cursor: pointer;
                margin-left: auto;
                .help-icon {
                    width: 18px;
                    height: 18px;
                }
                .help-text {
                    @media #{$breakpoint-below-tablet} {
                        display: none;
                    }
                    margin-left: 14px;
                    font-family: $National2Regular;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    align-items: center;
                    text-align: right;
                    color: $Brand-Black;
                }
            }
            .payment-help-btn {
                margin-left: auto;
                @media #{$breakpoint-below-tablet} {
                    display: none;
                }
                &.disable-supportbtn {
                    pointer-events: none;
                }
            }
            .sign-in-container {
                margin: auto 0;
                height: 24px;
                display: flex;

                .account-container {
                    margin: auto 0 auto auto;
                    cursor: pointer;

                    .account-icon {
                        width: 18px;
                        height: 18px;
                    }
                    .sign-in-text {
                        font-family: $National2Regular;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        margin-left: 11px;
                        align-items: center;
                        text-align: right;
                        color: $Brand-Black;
                        width: fit-content;
                        @media #{$breakpoint-below-tablet} {
                            display: none;
                        }
                    }
                }
            }
            .right-nav-item:last-of-type {
                @media #{$breakpoint-above-tablet} {
                    margin-left: auto !important;
                }
            }
            .header-close-btn {
                margin-left: auto;
                cursor: pointer;
                @media #{$breakpoint-above-tablet} {
                    display: none;
                }
                img {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
