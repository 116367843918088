.tippingInfoContent {
    width: 271px;
    height: 21px;
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.1px;
    color: #494949;
}
.donationbutton{
    .labelContent {
        @media #{$mobile-only} {
            margin-left: 15px !important;
        }
    }
    .info-label{
        @media #{$mobile-only} {
            margin-top: 24px !important;
        }
    }
}

.tipping-details {
    margin-top: 40px;
    margin-bottom: 40px;
    @media #{$mobile-only} {
        margin-top: 10px !important;
    }
    .button-contained[data-type="primary"] {
        // background: #202124;
        border-radius: 22px;
    }
    .center-element{
        margin: 0 10px !important;
        padding-left: 0;
        @media #{$mobile-only} {
            margin: auto !important;
            width: auto !important;
          }
    }
    .alert-warning {
        color: #{$gray-lightbg};
        font-weight: 400;
        background-color: inherit;
        border-color: inherit;
        font-size: 14px !important;
        font-family: #{$National2Regular};
    }
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    cursor: pointer;
}

.flex-container>div {
    margin: 10px;
    width: 88px;
    height: 61px;
    padding: 8px;
    border: 1px solid #202124;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;

    @media #{$breakpoint-between-ipad-tablet-and-medium-desktop}{
        margin: 8px;
        width: 80px;
    }

    @media #{$mobile-only} {
        width: 82px;
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7-landscape}{
        width: 62px;
        height: 50px;
        margin: 6px !important;
    }
    @media #{$breakpoint-tablet-only}{
        width: 46px;
        height: 50px;
        padding: 0px;
        padding-top: 6px;
        margin: 6px !important;
    }
    @media #{$breakpoint-ipad-12-pro-portrait}{
        width: 60px;
        height: 50px;
        padding: 7px;
    }
    @media #{$breakpoint-ipad-pro-11-landscape}{
        width: 62px;
        height: 50px;
    }
    @media #{$breakpoint-ipad-8-landscape}{
        width: 62px;
        height: 50px;
    }
}

.flex-container p {
    margin-bottom: 0px !important;
}

.tippingtext {
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #202124;
    @media #{$breakpoint-between-ipad-tablet-and-medium-desktop}{
        font-size: 14px;
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7-landscape}{
        font-size: 11px;
    }
    @media #{$breakpoint-tablet-only}{
        font-size: 11px;
    }
    @media #{$breakpoint-ipad-12-pro-portrait}{
        font-size: 11px;
    }
    @media #{$breakpoint-ipad-pro-11-landscape}{
        font-size: 11px;
    }
    @media #{$breakpoint-ipad-8-landscape}{
        font-size: 11px;
    }
}

.tip-text {
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.1px;
    color: #494949;
    margin-bottom: 14px;
    margin-left: 10px;
}

.tipping-section:hover {
    background: #202124;
    color: #FFFFFF;

    .tippingtext {
        color: #FFFFFF;
    }

    .tippingpercentage {
        color: #FFFFFF;
    }
}

.selected-tip {
    background: #202124;
    color: #FFFFFF;

    .tippingtext {
        color: #FFFFFF;
    }

    .tippingpercentage {
        color: #FFFFFF;
    }
}

.tippingpercentage {
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #202124;
    @media #{$breakpoint-between-ipad-tablet-and-medium-desktop}{
        font-size: 14px;
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7-landscape}{
        font-size: 11px;
    }
    @media #{$breakpoint-tablet-only}{
        font-size: 11px;
    }
    @media #{$breakpoint-ipad-12-pro-portrait}{
        font-size: 11px;
    }
    @media #{$breakpoint-ipad-pro-11-landscape}{
        font-size: 11px;
    }
    @media #{$breakpoint-ipad-8-landscape}{
        font-size: 11px;
    }
}

.enbledtippingpercentage {
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #626365;
 
    @media #{$breakpoint-between-ipad-tablet-and-medium-desktop} {
      font-size: 14px;
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
      font-size: 11px;
    }
    @media #{$breakpoint-tablet-only} {
      font-size: 11px;
    }
    @media #{$breakpoint-ipad-12-pro-portrait} {
      font-size: 11px;
    }
    @media #{$breakpoint-ipad-pro-11-landscape} {
      font-size: 11px;
    }
    @media #{$breakpoint-ipad-8-landscape} {
      font-size: 11px;
    }
  }

.deliveryinputfield {
    position: relative;
    margin-top: 14px;
    margin-left: 10px;
    .material-input.field input {
        padding-left: 15px;
        margin-top:6px;
        @media #{$mobile-only} {
            margin-top:4px;
        }
    }

    .material-input.field > input {
        font-weight: 500;
        font-family: $National2Medium;
        color: $Brand-Black;
    }
    .material-input.field  input:disabled {
        border-width:0px;
        -webkit-text-fill-color: $Brand-Black;
        opacity: 1;
    }
    .material-input.field input:not(:placeholder-shown) + label, .material-input.field input:focus + label{
        font-size: 16px;
    }

}

.cleariconsection {
    position: absolute;
    right: 0;
    top: 45%;
    cursor: pointer;
}

.drivertip-button {
    background-color: #202124 !important;
    color: #fff;
}

.drivertip-button-disabled {
    background-color: #dbdbdb !important;
    color: #494949;
    pointer-events: none;
}

.editsection {
    position: absolute;
    right: 0;
    top: 40%;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
}

.driver-tipsection {
    height: 53px;
}
.donationtext{
    padding-top: 11.5px;
}
.alert-warning{
    margin-left: 10px;
}
.alert-warningtext{
    font-size: 14px;
}
