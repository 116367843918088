.PostPaymentProcessComponent {
	.header {
		background: #fff;
		padding: 2% 2%;
		z-index: 9;
		border-bottom: 1px solid #dbdbdb;
	}
}

.closeIcon {
	margin-left: 86px;
}

.roundedQuesmark {
	top: 5px;
}

.bill-accordion-section{
	display: flex;
	// margin-top: 50px;
	@media #{$breakpoint-below-tablet} {
		display: unset;
	}

	.accordion-bill{
        margin-top: 20px !important;
	}
}

.OrderReceivedContainer {
	background: #fff;
	width: 100%;
	height: auto;
	margin-bottom: 50px;

	@media #{$breakpoint-below-tablet} {
		//height: 1169px;
	}

	.content {
		display: flex;

		@media #{$breakpoint-below-tablet} {
			display: block;
		}
	}

	.distaIframeContainer-delivered,.distaIframeContainer-cancelled {
		width: 51.5% !important;
		background: $white !important;
		@media #{$breakpoint-below-tablet} {
			width: 95.5% !important;
		}
	}

	.distaIframeContainer-delivered {
		margin: 40px auto !important;
	}

	.NotifyOrderPlaced {
		display: flex;
		justify-content: center;
		position: relative;
		background: #f8f7f5;
		width: 51.5%;
		margin-left: 130px;
		margin-top: 40px;
		// margin-bottom: 120px;
		height: 589px;
		margin-right: 21px;

		&.distaIframeContainer{
			width: 80%;
			@media #{$breakpoint-below-tablet} {
				width: 95.5%;
			}
			iframe{
				border: none;
				width: 100%;
				height: 589px;
			}
		}

		
		@media #{$breakpoint-below-tablet} {
			display: flex;
			justify-content: center;
			position: relative;
			background: #f8f7f5;
			width: 95.5%;
			margin-left: 10px;
			margin-top: 19px;
			margin-bottom: 12px;
			height: 502.5px;
			margin-right: 10px;

			&.distaIframeContainer{
				
				iframe{
					width: 100%;
					height: 502.5px;
				}
			}
		}

		.tickimage {
			margin-top: 70px;
			text-align: center;
			width: 100%;
			@media #{$breakpoint-below-tablet} {
				margin-top: 50px;
			}
		}

		.allSet {
			font-family: $National2Condensed;
			font-size: 74px;
			font-style: normal;
			font-weight: 700;
			line-height: 78px;
			letter-spacing: 2px;
			text-align: center;
			margin-top: 20px;

			@media #{$breakpoint-below-tablet} {
				font-weight: bold;
				font-size: 42px;
				line-height: 46px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Black;
			}
		}

		.idAndTime {
			font-family: $National2Condensed;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
			letter-spacing: 0.5px;
			text-align: center;
			margin-bottom: 40px;
			margin-top: 15px;

			@media #{$breakpoint-below-tablet} {
				font-weight: bold;
				font-size: 20px;
				line-height: 22px;
				letter-spacing: 1px;
			}
		}

		.allset-delivery {
			padding: 0 8%;
			margin-top: 17px;
			margin-bottom: 20px;
			@media #{$breakpoint-above-medium-desktop} {
				padding: 0 12%;
			}
		}

		.time-delivery {
			margin-top: 5px;
			margin-bottom: 30px;
			@media #{$breakpoint-below-tablet} {
				margin-bottom: 20px;
			}
			& .otp {
				@media #{$breakpoint-below-tablet} {
					font-size: 42px;
					line-height: 46px;
				}
			}
		}
		.red {
			color: $Brand-Red;
		}
		.DELIVERED-delivery,.white,.DELIVERED-delivery-allSet {
			color: $white !important; 
		}
		.ASSIGNED-delivery-allSet {
				padding: 0 16% !important;
		}


		.estimated-arrival-refund {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			text-align: center;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: $Dark-Gray;
			@media #{$breakpoint-below-tablet} {
				letter-spacing: 1px;
			}
		}

		.estimated-arrival {
			font-size: 20px;
			line-height: 24px;
			@media #{$breakpoint-below-tablet} {
				line-height: 22px;
			}
		}

		.refund-text {
			font-size: 24px;
			padding: 0 23%;
			@media #{$breakpoint-below-tablet} {
				font-size: 20px;
				line-height: 22px;
				padding: 0px;
			}
		}

		.driver-msg {
			@media #{$breakpoint-below-tablet} {
				// margin: 20px 20px 0px 36px;
				// padding: 0 35px;
				margin: 5% 5% 0 10%;
    			padding: 0 10%;
			}	
		}

		#statusMsg {
			margin-top: 20px;
			font-family: $National2Condensed;
			font-size: 17px;
			margin-bottom: 20px;
		}

		.linebreak {
			width: 662px;
			height: 1.08px;
			background-color: #e3e3e3;

			@media #{$breakpoint-below-tablet} {
				width: 100%;
			}
		}

		.saving {
			font-family: $National2Condensed;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: 25px;
			letter-spacing: 0.5px;
			text-align: left;

			@media #{$breakpoint-below-tablet} {
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				letter-spacing: 1px;
				margin-bottom: 10px;
			}
		}

		.savingdiv {
			display: flex;
			justify-content: space-between;
			margin-top: 43.2px;

			@media #{$breakpoint-below-tablet} {
				display: inline-block;
				justify-content: center;
				margin-top: 20px;
			}
		}

		.signup {
			@media #{$breakpoint-below-tablet} {
				margin-top: 20px;
			}
		}
	}

	.NotifyOrderPlaced::before {
		content: '';
		position: absolute;
		height: 26px;
		width: 50px;
		// margin-left: 47%;
		bottom: 98%;
		border-radius: 0 0 100px 100px;
		background: #fff;
	}

	.NotifyOrderPlaced::after {
		content: '';
		position: absolute;
		height: 26px;
		width: 50px;
		// margin-left: 47%;
		top: 98%;
		border-radius: 100px 100px 0 0;
		background: white;
	}

	.redCard {
		background: $Brand-Red;
	}

	.adjust-delivery-info{
		.delivery-info-daas{
			width: 48.3%;
			@media #{$breakpoint-below-tablet} {
				width: 95.5%;
			}
		}
	}

	.delivery-info-daas{
		margin-left: 130px;
		margin-top: 20px;
        margin-bottom: 50px;
        // margin-top: -50px;
        width: 51.5%;
		padding: 20px 30px 20px 30px; //30px 50px 30px 40px;
		min-height: 185px;
		max-height: 225px;
		background-color: #494949;
		@media #{$breakpoint-below-tablet} {
			width: 95.5%;
			margin-left: 12px;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.head-text{
		font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 22px !important;
        letter-spacing: 1px !important;
        text-transform: uppercase;
        color: #FFFFFF;
	}

	.dis-flex-first{
		display: flex;
		@media #{$breakpoint-below-tablet} {
			display: unset;
		}
	}

	.contact-text{
		margin-left: 10px;
		font-family: $National2TestRegular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #FFFFFF;
	}

	.set-opacity{
		opacity: 0.7;
	}

	.contact-text-mob{
		margin-left: 10px;
		font-family: $National2TestRegular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
		cursor: pointer;
        color: #FFFFFF;
	}

	.left-sec{
		margin: 5px 0;
		width: 70%;
		font-family: $National2TestRegular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
	}

	.created-driverstatus {
		width: 100%;
	}

	.rgt-sec{
		margin: 5px 0;
		width: 30%;
		font-family: $National2TestRegular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
	}

	.mob-wid{
		@media #{$breakpoint-below-tablet} {
			width: 100% !important;
		}
	}

	.algn-rgt{
		text-align: right;
	}

	.algn-left{
		text-align: right;
		@media #{$breakpoint-below-tablet} {
			text-align: unset;
		}
	}

	.hr-line{
		height: 1px;
		opacity: 0.25;
		margin: 15px 0;
		background: #FFFFFF;
	}

	.dis-flex-sec{
		display: flex;
	}

	.mr-set{
		margin: 5px 0;
	}

	.accordion-bill{
		margin-left: 20px; //130px;
        // margin-bottom: 50px;
        margin-top: 40px;
        width: 30%; // 51.5%;
		position: relative;
		height: fit-content;
		@media #{$breakpoint-below-tablet} {
			width: 95.5%;
			margin-left: 12px;
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.bill-container.global-accordian-container{
			border: unset;
		}
		.bill-container  > .global-accordian-header-container{
			height: unset;
			padding: unset;
		 }
		.accordion-Title{
			// margin-top: 39px;
            justify-content: space-between;
			padding: 20px 30px 20px 30px;
		}
		.Bill{
			width: 100%;
			border: unset;
			margin-top: unset !important;
			padding-left: unset !important;
		}
		.bill-accordian{
			.global-accordian-header:first-of-type{
				font-family: $National2Condensed !important;
				text-transform: uppercase;
				font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 1px;
                text-align: center;
			}
			.global-accordian-header{
				font-family: $National2Condensed !important;
				text-transform: uppercase;
				font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.5px;
                text-align: center;
			}
		}
	}

	.accordion-bill-info {
		border: 1px solid #ddd;
		margin: 0;
		padding: 0;
	}

	.Bill {
		position: relative;
		border: 1px solid #e3e3e3;
		background: #fff;
		width: 26.5%;
		margin-right: 130px;
		margin-top: 40px;
		// margin-bottom: 120px;
		padding-left: 40px;
		padding-right: 38px;
		height: auto;

		@media #{$breakpoint-below-tablet} {
			position: relative;
			border: 1px solid #e3e3e3;
			background: #fff;
			width: 95.5%;
			margin-top: 20px;
			margin-bottom: 60.5px;
			// padding-left: 20px;
			padding-right: 20px;
			//height: 598px;
			// margin-left: 10px;
			margin-right: 10px;
		}
	}

	.orderTitle {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;

		@media #{$breakpoint-below-tablet} {
			margin-top: 43px;
		}

		.yourOrder {
			font-family: $National2Condensed;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
			letter-spacing: 0.5px;
			text-align: left;
			width: 68%;
			text-transform: uppercase;

			@media #{$breakpoint-below-tablet} {
				width: 70%;
			}
		}

		.OrderClass {
			text-transform: uppercase;
		}

		.orderId {
			font-family: $National2Condensed;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: 25px;
			letter-spacing: 0.5px;
			text-align: center;
		}
	}

	.orderingDateTime {
		font-family: $National2Regular;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: left;
		height: 18px;
	}

	.postorder-payment-details {
		height: 18px;
    	line-height: 16px;
		margin-top: 20px;

		& .payment-header {
			font-family: $National2Condensed;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			letter-spacing: 0.5px;
			text-align: left;
		}

		& .payment-details {
			margin-top: 10px;
			line-height: 10px;
			margin-bottom: 20px;

			& .payment-completed {
				font-family: $National2Regular;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				letter-spacing: 0px;
				text-align: left;
			}
		}
	}

	.orderFrom {
		font-family: $National2Condensed;
		font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #202124;
		margin-top: 35px;
	}

	.place {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: $Brand-Black;
		padding-top: 10px;
	}

	.fnt-bld{
		font-weight: bold;
	}

	.hrs-view{
		letter-spacing: -0.1px;
		color: #494949;
	}

	.mob-display{
		@media #{$breakpoint-below-tablet} {
			display: flex;
			width: 100%;
		}
	}

	.Address {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: #494949;
	}

	.ContactDetail {
		display: flex;
		justify-content: space-between;
		padding-top: 10px;

		.phoneNum {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			text-decoration: underline;
			cursor: pointer;
		}

		.anchorTag {
			color: black;
		}

		.phoneIcon {
			padding-right: 10px;
		}
	}

	.price {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
	}

	.priceList {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}

	.fonts {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: #494949;
		text-transform: capitalize;
	}

	.amount {
		font-family: $National2Regular;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		text-align: right;
		color: $Brand-Black;
	}

	.total {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: $Brand-Black;
	}

	.payment-type{
		font-family: $National2Regular;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: #494949;
		margin-top: 5px;
		text-transform: capitalize;
	}

	.totalAmount {
		display: flex;
		justify-content: space-between;
		margin-top: 19.82px;
		margin-bottom: unset;
	}

	.mt-5{
		margin-top: 5px !important;
	}

	.hr-payment-type{
		height: 1px;
		margin: 15px 0;
        background: #E3E3E3;
	}

	.ViewMore {
		font-family: $National2TestRegular;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		text-align: center;
		text-decoration-line: underline;
		color: $Brand-Black;
		margin-top: 10px;
		margin-bottom: 21px;
	}

	.line {
		margin-top: 14px;
	}

	.global-accordian-container .global-accordian-header-container {
		height: 60px;
		padding: 19px;
		padding-left: 6px;
	}

	.global-accordian-container {
		border-top: 2px solid black;
		border-bottom: 2px solid black;
	}

	.global-accordian-container .global-accordian-header-container .global-accordian-arrow {
		width: 16px;
		height: 12px;
	}

	.global-accordian-container .global-accordian-header-container .global-accordian-header {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		color: $Brand-Black;
	}

	.number {
		background: #e3e3e3;
		border-radius: 4px;
		padding-right: 4px;
		padding-left: 4px;
		margin-right: 3px;
	}

	.foodName {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: $Brand-Black;
	}

	.ItemPrice {
		text-align: right;
	}

	.billDescription {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 31px;
	}
}

.SavedNotification {
	width: 265px;
	height: 62px;
}

.toast-for-favorite {
	margin: auto 0 !important;
	padding: 6px !important;
}

.notifyText {
	font-family: $National2Regular;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #fff;
	padding: 0px 0px 0px 35px;

	@media #{$mobile-only} {
		padding: 0px 0px 0px 2px;
	}
	&.notifyText-in {
		justify-content: center;
		padding-left: 0;
	}
}

.TickImg {
	padding-right: 10px;
}

.notify {
	display: flex;
	justify-content: flex-end;
	margin-top: -32px;
	margin-right: -111px;

	@media #{$breakpoint-below-tablet} {
		display: flex;
		justify-content: center;
		position: absolute;
		margin-top: 0;
		margin-right: 0;
		margin-left: 40px;
	}
}

// TrackOrderScreen Error Message Display
.inputbox {
	&.trackOrderScreen {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		& #saveTrackOrderScreen{
			width: 380px;
		}
	}
	+ .buttonItems {
		margin-top: 3.5%;
	}
}

#saveTrackOrderScreen {
	.error-msg{
		font-size: 14px;
	}
	@media #{$mobile-only} {
	margin-left: 0%;
	+ .buttonItems {
		margin-top: 20px;
	}
   }

   @media #{$breakpoint-tablet-only} {
	margin-left: 22%;
	+ .buttonItems {
		margin-top: 20px;
	}
   }
}
.circle-topp{
	height: 19px;
	width: 42px;
	border-radius: 0 0 200px 200px;
	background: #fff;
	margin: -2px auto auto;
	border-bottom: 1px solid #e3e3e3;
  }
  .circle-bottomm{
	height: 19px;
	width: 42px;
	border-radius: 200px 200px 0 0;
	background: #fff;
	bottom: -1px; 
	left: calc(50% - 21px);
	position: absolute;
	margin: 0 auto;
	border-top: 1px solid #e3e3e3;
  }