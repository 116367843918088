.get-help-offers-container {
	width: 80%;
	margin: auto;

	@media #{$breakpoint-below-tablet} {
		width: 100%;
	}

	.get-help-section {
		background-color: #fff;
		
		.d-none {
			display: none;
		}

		.Messageimage {
			margin-right: 14px;
			margin-bottom: 2px;
		}

		@media #{$breakpoint-below-tablet} {
			width: 100%;
		}

		.blkBorder {
			border: 1.5px solid #202124;
		}

		.deepBlack {
			color: #202124 !important;
		}

		.mt-5 {
			margin-top: 5% !important;
		}

		.mb-1rem {
			margin-bottom: 1rem;
		}

		.mr5 {
			margin-right: 5%;

			@media #{$breakpoint-below-tablet} {
				margin-right: unset;
			}
		}

		.mt-adjust {
			margin-top: 10%;

			@media #{$breakpoint-below-tablet} {
				margin-top: 5%;
			}
		}

		.mb-5 {
			margin-bottom: 5%;

			@media #{$breakpoint-below-tablet} {
				margin-bottom: unset;
			}
		}

		.font-bold {
			font-weight: bold !important;
		}

		.header-text {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #202124;
		}

		.section-text {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: #494949;
			background-color: transparent;
			min-height: 30px;
			display: block;
			margin-top: 20px !important;
		}

		.help-text {
			text-align: center;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: #202124;
			font-style: normal;
			font-family: $National2Condensed;
			font-weight: bold;
			font-size: 38px;
			line-height: 43px;
			margin-top: 60px;

			@media #{$breakpoint-below-tablet} {
				font-size: 28px;
				line-height: 22px;
				text-align: center;
				margin-top: 30px;
			}
		}

		.help-block-container {
			display: flex;
			flex-wrap: wrap;
			margin: 60px auto auto auto;
			width: 80%;

			@media #{$breakpoint-below-tablet} {
				width: 85%;
				margin: 35px auto auto auto;
			}

			.header-text {
				display: flex;
				align-items: center;
				justify-content: start;

				span {
					background: #fff;
				}
			}

			.header-text::after {
				background: #202124;
				height: 1px;
				flex: 1;
				content: '';
				margin-left: 11px;
				opacity: 0.3;
			}

			.get-help-block {
				display: flex;
				flex-direction: column;
				padding: 0;
				width: 30%;

				@media #{$breakpoint-below-tablet} {
					width: 100%;
					margin-bottom: 40px !important;
				}

				button {
					@media #{$breakpoint-below-tablet} {
						width: 100%;
					}
				}

				.nearest-kfc-details {
					font-family: $National2Regular;
					font-weight: 500;
					font-style: normal;

					span.section-text {
						display: block;
					}
				}

				.offer-button-container {
					margin-top: 20px;
					margin-bottom: 30px;

					.help-btn {
						padding: 11px 40px;
						border-radius: 200px;
						height: 44px;
						cursor: pointer;
						outline: none;
						width: 100%;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 24px;
						font-family: $National2Medium;
						background-color: transparent;
						border: 1.5px solid #202124;
						color: #202124;
					}
				}
			}
		}

		.faq-survey-section {
			display: flex;
			justify-content: space-between;
			margin: 60px auto auto auto;
			border-radius: 8px;
			background-color: #f8f7f5;
			padding: 50px 100px;

			@media #{$breakpoint-below-tablet} {
				flex-direction: column;
				margin: 10px 0 0 0 !important;
				border-radius: 8px;
				padding: 50px 30px 0 30px;
			}

			.faq-survey-block {
				display: flex;
				width: 100%;
				flex-direction: column;
				padding: 0;

				@media #{$breakpoint-below-tablet} {
					margin-bottom: 60px;
				}

				.m-adjust {
					margin: 5% 5% 0 11%;
				}

				.m1-adjust {
					margin: 5% 5% 0 10%;
				}

				.section-text {
					margin-top: 10px !important;
					button {
						background-color: #f8f7f5;
						cursor: pointer;

						@media #{$breakpoint-below-tablet} {
							width: 100%;
						}
					}
				}

				.faq-text-block {
					display: flex;
					flex-wrap: wrap;
					margin-top: 10px;

					@media #{$mobile-only} {
						margin-top: unset;
						margin-bottom: 10px;
					}

					& a {
						text-decoration: none;
					}

					.section-text {
						border-radius: 200px;
						height: 3em;
						margin-right: 10px;
						margin-bottom: 10px;
						font-weight: 400;
						padding: 8px 15px;
						color: #494949;
						font-family: $National2Medium;
						border: 2px solid #e3e3e3;
						cursor: pointer;						
						@media #{$mobile-only} {
							margin-bottom : unset;
							padding: 5px 14px;
							font-size: 12px;
							line-height: 22px;
						}			
					}
				}

				.offer-button-container {
					margin-top: 20px;
					margin-bottom: 30px;

					.help-btn {
						padding: 11px 30px;
						border-radius: 200px;
						max-width: 168px;
						height: 44px;
						cursor: pointer;
						outline: none;
						width: 100%;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 24px;
						font-family: $National2Medium;
						background-color: transparent;
						border: 1.5px solid #202124;
						color: #202124;
					}
				}
			}
		}
	}
}

.get-help-header-image {
	width: 100%;
	height: 280px;
	background-color: $Brand-Black;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;

	@media #{$breakpoint-below-tablet} {
		height: 205px;
	}

	h1.get-help-image-text {
		font-family: $National2Condensed;
		color: white;
		font-style: normal;
		font-weight: bold;
		font-size: 74px;
		line-height: 78px;
		text-align: center;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin: auto;

		@media #{$breakpoint-below-tablet} {
			font-size: 42px;
			line-height: 46px;
			letter-spacing: 1px;
		}
	}
}

.need-help-modal {
	.modal-content{
		@media #{$breakpoint-above-destop-below-large-desktop} {
			width: 72%;
			left: 14%;
		}

		@media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
			width: 70%;
            left: 15%;
		}
		@media #{$breakpoint-ipad-12-pro-portrait} {
			width: 70%;
			height: 55%;
			left: 15%;
			top: 12%;
		}
		.modal-header{
			.header-title{
				font-size: 24px;
				line-height: 24px;
				font-style: normal;
				@media #{$breakpoint-below-tablet} {
					font-size: 20px;
					line-height: 22px;
				}
			}
		}
		.modal-body {
			padding: 0%;
			background: none;

			.get-help-offers-container {
				width: 100%;
				.get-help-section {
						
					.help-text {
						margin-top: 49px;
						@media #{$breakpoint-below-tablet} {
							margin-top: 30px;
						}
					}
					.section-text{
						.bold-text{
							font-weight: bold;
						}
					}
					.help-block-container{
						gap: 10%;
						.get-help-block{
							width: 45%;
							@media #{$breakpoint-below-tablet} {
								width: 100%;
								margin-bottom: 40px !important;
							}
						}
					}
					.faq-survey-section{
						margin: 0px !important;
						padding: 40px 90px 112px;
						@media #{$breakpoint-above-large-desktop} {
							padding: 50px 112px 138px;
						}
						@media #{$breakpoint-below-tablet} {
							flex-direction: column;
							margin: 10px 0 0 0 !important;
							border-radius: 8px;
							padding: 50px 32px;
						}
						@media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
							padding: 50px 30px 100px 68px;
						}
						@media #{$breakpoint-samsung-galaxy-tab-s7} {
							padding: 50px 47px 230px 54px
						}
						@media #{$breakpoint-iphone-12-pro-portrait} {
							padding: 50px 30px 56px 30px;
						}
						@media #{$breakpoint-ipad-12-pro-portrait, $breakpoint-ipad-pro-12-landscape} {
							padding: 50px 75px 170px;
						}
						.faq-survey-block{
							.mt-5{
								margin-top: 20px !important;
							}
							.view-all-faq-btn{
								width: fit-content;
								margin-top: 20px !important;
								@media #{$mobile-only} {
											width: 100%;
										}

							}
						}

					}
				}
			}
		}
	} 
}