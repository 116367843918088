.recommended-container {
	overflow: hidden;
	background-color: $Mashed-Potato-Gray;
	
	& .recommendedCard-container {
		margin-left: 9%;

		@media #{$breakpoint-below-tablet} {
			margin: 0;
			display: flex;
			flex-direction: column;
			margin-left: 20px;
		}

		& .recommendedContainerHeader-items {
			display: flex;
			align-items: center;
			margin: 20px 35px;

			span.count_box {
				display: none;
				font-size: 14px;
				color: $Brand-Black;
				margin-bottom: 5px;
			}

			@media #{$breakpoint-below-tablet} {
				flex-direction: row;
				margin: 5px 20px 5px 5px;

				span.count_box {
					display: block;
				}
			}

			& .recommendedContainer-header {
				margin-right: 652px;
				font-family: $National2Condensed;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 38px;
				line-height: 43px;
				font-weight: bold;

				@media #{$breakpoint-below-tablet} {
					width: 100%;
					height: 32px;
					font-size: 24px;
					line-height: 32px;
					margin: 0;
					margin-bottom: 10px;
				}
			}
			& .pdp-carousal-header {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 24px;
				text-transform: uppercase;
				color: $Brand-Black;
				display: flex;
				flex-direction: column;
				letter-spacing: 0.5px;
		
				.sub-header {
					font-family: $National2Regular;
					display: block;
					font-size: 16px;
					line-height: 24px;
					text-transform: initial;
					font-weight: lighter;
					letter-spacing: -0.1px;
					color: #858585;
					margin-top: 6px;

				}
			}
		}

		& .recommendedCard-list {
			display: flex;
			padding: "5px 0";
			flex-direction: column;

			@media #{$breakpoint-below-tablet} {
				width: 100%;
			}

			& .recommended-slideContainer {
				width: 80%;
				margin-bottom: 8.3%;
				margin-left: 2%;

				@media #{$breakpoint-below-tablet} {
					display: none;
				}

				& .recommended-slider {
					width: 100%;
					height: 3px;
					-webkit-appearance: none;
					background: $Light-Gray;
					outline: none;
					border-radius: 5%;
					-webkit-transition: 0.2s;
					transition: opacity 0.2s;
				}

				& .recommended-slider::-webkit-slider-thumb {
					-webkit-appearance: none;
					appearance: none;
					width: 274.15px;
					height: 3px;
					border-radius: 5%;
					background: $Brand-Black;
					cursor: pointer; /* Cursor on hover */
				}

				& .recommended-slider::-moz-range-thumb {
					-webkit-appearance: none;
					appearance: none;
					width: 10%;
					height: 2px;
					border-radius: 5%;
					background: $Brand-Black;
					cursor: pointer; /* Cursor on hover */
					border: 0;
				}
			}

			& .recommendedCarousal-list {
				margin-bottom: 2.5%;

				& .slick-prev {
					left: -70px;

					@media #{$breakpoint-below-tablet} {
						display: none;
					}
				}

				& .slick-prev::before {
					font-family: unset;
					font-size: 36px;
					content: "\2039"; // \203A HTML Entry for ‹
					color: $Brand-Black;
					background-color: white;
					border-radius: 50%;
					display: inline-block;
					width: 48px;
					height: 48px;
					text-align: center;
					box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
				}

				& .slick-prev:focus::before {
					outline: 1px auto -webkit-focus-ring-color;
				}

				& .slick-list {
					width: 135%;
					padding-left: 2rem;

					@media #{$breakpoint-below-tablet} {
						padding-left: 0;
					}

					& .slick-track {
						margin-left: 0;

						& .slick-slide {
							margin-left: 37px !important;
							width: 300px;
							height: 480px !important;
							margin-right: 20px;

							@media #{$breakpoint-below-tablet} {
								margin-left: 0 !important;
								height: 485px !important;
							}

							& .small-menu-product-image {
								width: 300px;
								height: 229px;
							}
						}
					}
				}

				& .slick-next {
					right: 145px;

					@media #{$breakpoint-below-tablet} {
						display: none;
					}
				}

				& .slick-next::before {
					font-family: unset;
					font-size: 36px;
					content: "\203A"; // \203A HTML Entry for ›
					color: $Brand-Black;
					background-color: white;
					border-radius: 50%;
					display: inline-block;
					width: 48px;
					height: 48px;
					text-align: center;
					box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
				}

				& .slick-next:focus::before {
					outline: 1px auto -webkit-focus-ring-color;
				}

				& .medium-menu-product-card {
					position: relative;
					height: 475px;
					font-family: $National2Regular;
					background: $white;
					box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
					border-radius: 8px;
					// margin-right: 20px;
					margin-bottom: 70px;

					@media #{$breakpoint-below-tablet} {
						height: 445px;
					}

					.medium-menu-product-image {
						width: 100%;
						height: 210px;
						margin-bottom: 10px;
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}

					.specials-product-ribbon {
						position: absolute;
						top: 3%;
						left: 6%;
						transform: scaleX(1.5);
					}

					.specials-ribbon-text {
						position: absolute;
						top: 4%;
						left: 0%;
						font-family: $National2Condensed;
						font-style: normal;
						font-weight: bold;
						font-size: 18px;
						line-height: 20px;
						letter-spacing: 0.5px;
						text-transform: uppercase;
						color: $white;
					}

					.discount-product-ribbon {
						position: absolute;
						top: 3%;
						left: -12%;
						transform: scaleX(1.5);
					}

					.discount-ribbon-text {
						position: absolute;
						left: 0;
						top: 3%;
						font-family: $National2Condensed;
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: $Brand-Red;
					}

					.exclusive-product-ribbon {
						position: absolute;
						top: 3%;
						left: -3%;
						transform: scaleX(2.5);
					}

					.menu-product-save {
						position: absolute;
						left: 87.86%;
						cursor: pointer;
						z-index: 1;

						@media #{$breakpoint-above-tablet} {
							right: 3.57%;
							top: 2.11%;
							bottom: 92.84%;
						 }
					}

					.menu-card-content {
						margin-left: 20px;
						margin-right: 20px;
						margin-bottom: 20px;

						.medium-menu-product-header {
							margin-bottom: 5px;
							font-style: normal;
							font-size: 16px;
							line-height: 24px;
							font-weight: 400;
							font-family: $National2Medium;
							color: $Brand-Black;
						}

						.menu-vegOrNonVeg-icon {
							display: inline-block;
						}

						.medium-menu-vegOrNonVeg {
							display: inline-block;
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 22px;
							margin-bottom: 5px;
							color: $Dark-Gray;
							margin-left: 7px;

							@include Dot();
						}

						.medium-menu-product-price {
							font-family: $National2Medium;
							color: $Brand-Black;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							margin-bottom: 5px;
						}

						.medium-menu-product-description {
							font-style: normal;
							font-weight: normal;
							font-family: $National2Regular;
							font-size: 14px;
							line-height: 24px;
							letter-spacing: -0.1px;
							color: $Dark-Gray;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3; /* number of lines to show */
							-webkit-box-orient: vertical;
						}

						.medium-menu-cart-button {
							display: flex;
							justify-content: center;
							width: 80%;
							height: 44px;
							margin-top: 10px;
							position: absolute;
							left: 10%;
							right: 10%;
							bottom: 1rem;
						}

						.PriceVegNonveg{
							display: flex;
							margin-top: 12px;
						}
					
					}
				}
			}
		}
		&.recommendedCard-container-au {
			@media #{$breakpoint-below-tablet} {
				max-width: 88%;
			}
			.recommendedContainerHeader-items {
				margin: 60px 0 36px 0 !important;
				@media #{$breakpoint-below-tablet} {
					margin-bottom: 25px !important;
				}
			}	
			& .recommendedCarousal-list {
				margin-bottom: 0;
				& .slick-prev, & .slick-next {
					top: 30%;
					z-index: $z-index-max - 1;
					&:before {
						outline:none !important;
					}
				}
				& .slick-prev {
					left:-10px;
				}
				.slick-list {
					padding-left: 10px !important;
					margin-left: -10px;
				}
				.slick-list .slick-track .slick-slide {
					height: auto !important;
				}
				& .medium-menu-product-card {
					height: 330px;
					cursor: pointer;
					& .menu-card-content {
						// padding: 10px 0 0 0 ;
						display: inline-block;
						margin: 15px 20px 20px 20px;
						width: 87%;
						& .medium-menu-product-header, & .medium-menu-product-price {
							font-weight: 400;
						}
						& .medium-menu-vegOrNonVeg .Dot {
							background-color: $Dark-Gray;
							height: 3px;
							width: 3px;
							border-radius: 30px;
							margin: 0 5px 2px 2px;
							opacity: 0.5;
						}
						& .Price-and-KJ-info{
							display: block;
						}
						& .medium-menu-price-and-KJ, & .medium-menu-vegOrNonVeg {
							display: inline-block;
							font-size: 15px;
							line-height: 22px;
							color: $Dark-Gray;
							margin-left: 0;
							margin-bottom: 6px;
							font-weight: 400;
							font-family: $National2Regular;
						}
						& .cartItem-serves .Dot {
							margin: 0 5px 2px 5px;	
						}
						.medium-menu-product-header{
							font-family: $National2Medium;
							font-style: normal;
							font-weight: 400;
							font-size: 16px;
							line-height: 24px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
				& .small-menu-product-card {
					height: auto;
					cursor: pointer;
					background: $white;
					box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
					border-radius: 4px;
					width: 98%;
					//width:300px;
					min-height: 330px;
					margin: 0 20px 70px 0;
					position: relative;
					@media #{$breakpoint-below-iphone5} {
						width: 95%;	
					}
					& .description-and-image {
						min-height: 70px;
						@include backgroundImage();
						border-radius: 4px 4px 0 0;
					}
					& .small-menu-product-image {
						bottom: 65.28%;
						width: 100% !important;
						height: auto !important;
					}
					& .small-menu-product-header {
						font-family: $National2Medium;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
						color: $Brand-Black;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-box-orient: vertical;
						margin-bottom: 5px;
						width: 100% !important;
						padding: 15px 15px 10px 15px;
						@media #{$mobile-only}{
							padding: 15px 15px 0px 15px;
							margin-bottom: 0px;
						}
					}
					& .Price-and-KJ-info-catering {
						.small-menu-price-and-KJ{
							display: block !important;
						}
					}
						& .small-price-and-KJ-info{
							display: block !important;
						}
					& .small-price-and-KJ-info, .Price-and-KJ-info-catering {
						& .small-menu-price-and-KJ, & .medium-menu-vegOrNonVeg {
							display: inline-block;
							font-size: 16px;
							line-height: 22px;
							color: #494949;
							margin:8px 0 0 0;
							font-weight: 400;
							font-family: $National2Regular;
						}
						& .medium-menu-vegOrNonVeg .Dot {
							background-color: $Dark-Gray;
							height: 3px;
							width: 3px;
							border-radius: 30px;
							margin: 0 5px 2px 2px;
							opacity: 0.5;
							display: inline-block;
						}
						& .cartItem-serves .Dot {
							margin: 0 5px 2px 5px;
						}
					}
					& .medium-menu-product-price {
						font-size: 16px;
						font-weight: 600;
						padding-top: 8px;
						color: $Brand-Black;
						@media #{$mobile-only} {
							font-weight: 400;
							min-height: 30px;
						}
					}
				}
			}
			& .recommended-slideContainer {
				width: 100%;
				margin-left: 0;
			}
		}
	}

	/* css for PDP screen recommendation container  & Make it a combo*/
	&.pdp-recommendations , &.pdp-combo{
		& .recommendedCard-container {
			max-width: 85%;
    		margin: auto;
			width: 1181px;
			&.recommendedCard-container-in {
				width: unset;
			}
			& .recommendedCard-list{
				& .recommendedCarousal-list{
					& .slick-prev {
						display: none;
					}
					& .slick-next{
						right: 0;
					}
					& .slick-list {
						padding-left: 0;
							&  .slick-track {
								& .slick-slide {
									margin-left: 0px!important;
								}
							}
						}
					}
				}
			@media #{$breakpoint-ipad-mini-portrait} {
				max-width: 90%;
			}
			@media #{$breakpoint-below-and-above-medium-desktop}{
				   padding-left: 1.5%;
				
				  }
				  @media #{$breakpoint-above-desktop}{
					padding-left: 0%;
				 
				   }
				
		}

		& .recommendedContainerHeader-items {
			margin: 20px 35px 20px 0!important;
		}
		
		& .recommendedContainer-header {
			margin-right: 0px!important;
		}
		}

	&.pdp-combo{
		background: $white;
	}

}
.recommended-card-sopac{
	& .product-image-parent-container{
		@include backgroundImage();
		min-height: 70px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding-top: 70%;
		@media #{$breakpoint-above-tablet} {
			margin-bottom: 0;
		}
	}
	& .medium-menu-product-image {
		width: 100% !important;
		height: auto !important;
		margin-bottom: 10px;
	}
}

.recommended-empty-cart-start-order-button {
	margin-left: 42px;
	margin-right: 19px;
	  @media #{$breakpoint-googlepixel-5}{
		margin-left: 53px;
		
		  }
	  @media #{$breakpoint-iphone-11-pro-portrait}{
		
		  margin-left: 51px;
		  }
	  @media #{$breakpoint-iphone13}{
			margin-left: 53px;
		  }
		  @media #{$breakpoint-samsung-s21plus}{
			
			margin-left: 49px;
			margin-right: 19px;
		  }
		  @media #{$breakpoint-iphone-12-pro-portrait}{
			
			margin-left: 35px;
            margin-right: 25px;
		  }
		  @media #{$breakpoint-above-tablet}{
			
			margin-left: 8px;
           
		  }
		  @media #{$breakpoint-iphone13-pro-max} {
			margin-left: 35px;
		}
}