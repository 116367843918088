.marketing-hero-bg-au{
  height: 630px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 1023px){
    height: 441px;
  }
  @media (max-width: 767px){
    height: auto;
  }
}
.market-hero-content{
  @media #{$mobile-only}{
    padding-top: 12rem;
  }
}
.market-hero-global-aus {
  .videoItem-dots{
    @media #{$mobile-only}{
        .slick-dots{
          bottom: 1.7rem;
        }
    }
  }
  .single-div-container{
    @media #{$breakpoint-samsung-galaxy-s7-tab} {
      .largeTextHero-aus{
        width: 50%;
      }
    }
    .strip-continer{
      left: 0 !important;
      position: relative;
      right: 0;
      .strip-Icon{
        .strip-icon-img{
          @media #{$breakpoint-desktop-small}{
            height: 30px;
          }
          @media #{$breakpoint-below-tablet}{
            height: 30px;
          }
          @media #{$mobile-only}{
            width: 39px;
            height: 20px;
            margin-top: -10px;
          }
        }
      }
    }
    .strip-Icon{
      margin-left: 0;
      @media #{$mobile-only}{
       text-align: center;
       align-items: center;
      }
      @media #{$breakpoint-below-tablet}{
        text-align: center;
        align-items: center;
      }
    }
    .carausel-image{
      width: auto;
      height: 100%;
      position: absolute;
      left: 53%;
      top: 0;
      @media #{$breakpoint-below-tablet}{
        left: 22%;
        top: 0;
        width: 65%;    
        margin-bottom: 20px;
        position: relative;
      }
      @media #{$mobile-only}{
        left: 0;
        top: 0;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
      }
    }
    .market-hero-description{
      padding-left: 0px;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      @media #{$breakpoint-below-tablet}{
        padding-left: 15px;
      }
      @media #{$mobile-only}{
        padding-left: 15px;
      }
     }
     .largeTextHero-aus{
      margin-top: 8.3%;
      @media #{$breakpoint-below-tablet}{
        width: 50% !important;
        margin-top: 0;
      }
      @media #{$mobile-only}{
        width: 100% !important;
        margin-top: 0;
      }
     }
     .greeting-heading{
      top: 0;
      @media #{$breakpoint-desktop-small}{
        top:-16px
      }
      @media #{$breakpoint-below-tablet}{
        width: 100%;
      }
      @media #{$mobile-only}{
        width: 60%;
      }
     }
     .carauselOrderNow-NoteLine{
      margin-bottom: 50px;
     }
  }
  .transperant-img-dots{
    @media #{$mobile-only}{
      .slick-dots{
        bottom: 5rem !important;
      }
    }
  }
  .video-content{
    .market-hero-description{
      position: absolute !important;
      bottom: 0;
    }
  }
  .smallTxtHero {
    position: absolute;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: $uppercase;
    color: $Brand-Black;
    left: 229px;
    right: 50.83%;
    top: 40px;
    bottom: 84.85%;
  }

  .largeTextHero {
    position: absolute;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 76px;
    letter-spacing: 1px;
    text-transform: $uppercase;
    color: $Brand-Black;
    left: 229px;
    right: 40.82%;
    top: 17.68%;
    bottom: 43.94%;
  }

  .color-white {
    color: $white;
  }

  .color-black {
    color: $Brand-Black;
  }

  .carausel-paging {
    border: 1px solid $Dark-Gray;
    opacity: 0.5;
    box-sizing: border-box;
    //height: 27px;
    //width: 27px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: $center;
    // padding-top: 10%;

    @media #{$breakpoint-below-tablet} {
      height: 15px;
      width: 15px;
      background-color: $Dark-Gray;
    }
  }

  .carausel-pagingNo {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: $center;
    color: $Dark-Gray;
    top: 4px;
    position: relative;

    @media #{$breakpoint-below-tablet} {
      display: none;
      border: 2px solid $Dark-Gray;
    }
  }

  .slick-active .carausel-pagingNo {
    color: $Brand-Black;
  }

  .carauselOrderNow {
    flex-direction: row;
    justify-content: $center;
    align-items: $center;
    margin-top: 30px;
    border-radius: 200px;
    position: absolute;
    left: 229px;
    right: 69.65%;
    bottom: 25.25%;
    font-family: $National2Regular;
    font-weight: $font-weight-500;
    padding: 10px 40px;
    // outline: 5px auto -webkit-focus-ring-color;
  }

  .slick-dots {
    //left: 229px;
    //bottom: 6%;
    //width: auto !important;
    max-width: 1180px;
    width: 100%;
    margin:0 auto;
    bottom: 84px;
    left: 0;
    right: 0;
    text-align: left;
    padding-left: 1.25rem;
    @media #{$breakpoint-above-destop-wide}{
      padding-left: 1.25rem;
    }
    @media #{$breakpoint-below-desktop-above-tablet}{
      left: 2%;
    }
    @media #{$breakpoint-desktop-small}{
      bottom: 92px;
      left: 3%;
      padding-left: 1.25rem;
    }
    @media (max-width: 1023px){
      bottom: 85px;
    }
    @media #{$mobile-only} {
      bottom: 88px;
      margin: 0 0 0 0px !important;
      padding-left: 7%;
    }
    @media #{$breakpoint-iphone-ios15}{
      padding-left: 8%;
    }
    @media #{$breakpoint-below-iphoneX}{
      padding-left: 8%;
    }
  }

  .slick-dots li {
    //margin: 0 10px 0 -8px !important;
    margin: 0 2rem 0 0!important;
    @media #{$breakpoint-below-tablet} {
      //margin: 0 10px 0 8px !important;
      margin:0 1rem 0 0!important;
      width: 20px;
      height: 20px;
    }

    @media #{$medium-only} {
      margin: 0 15px !important;
    }

    @media #{$mobile-only} {
      margin-right: 13px !important;
    }
  }

  .carausel-inner-positions {
    position: relative;
    width: 47vw;
    left: 11vw;

    @media #{$breakpoint-below-tablet} {
      left: 8vw;
    }
  }

  .carausel-text-span {
    position: absolute;
    top: 30px;
  }

  .CircularProgressbar {
    //height: 28px;
    //width: 28px !important;
    //margin-top: -5px;
    height: 40px;
    width: 40px!important;

    & .CircularProgressbar-trail {
      stroke-width: 3 !important;
      opacity: 0.7 !important;
      @media #{$mobile-only} {
        stroke-width: 9 !important;
        opacity: 1 !important;
      }
    }

    & .CircularProgressbar-path {
      stroke-width: 9 !important;

      @media #{$breakpoint-below-tablet} {
        stroke: $Brand-Black;
      }
    }

    @media #{$medium-only} {
      height: 20px;
      width: 21px !important;
      margin-top: -12px;
    }
  }

  .CircularProgressbar-text {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 40px !important;
    line-height: 22px;
    text-align: center;
    dominant-baseline: mathematical;
    // fill: $Brand-Black!important;
  }

  .slick-active {
    top: 3px;

    .CircularProgressbar {
      @media #{$mobile-only} {
        width: 9px !important;
        height: 9px !important;
      }
    }

    .carausel-paging {
      border: 1px solid $Brand-Black;
      background-color: transparent;
    }
  }

  @media #{$medium-only} {
    .carausel-text-span {
      top: 5%;
    }

    .smallTxtHero {
      font-size: 16px;
      line-height: 20px;
      left: 8%;
      right: 46.67%;
      top: 19.6%;
      bottom: 72.4%;
    }

    .largeTextHero {
      font-size: 22px;
      line-height: 24px;
      left: 8%;
      right: 46.67%;
      top: 31.6%;
      bottom: 49.2%;
    }

    .carauselOrderNow {
      margin-top: 5px;
      left: 8.67%;
      right: 51.87%;
      top: 58.8%;
      bottom: 23.6%;
    }

    .carausel-paging {
      height: 20px;
      width: 20px;
    }

    .CircularProgressbar-text {
      font-size: 35px;
    }
    /*
        .slick-dots {
          left: 12%;
        }
        */
  }

  @media #{$breakpoint-between-mob-tablet} {
    .carauselOrderNow {
      top: 53%;
      right: 50.87%;
    }
    /*
        .slick-dots {
          left: 10% !important;
        }
    */
    .largeTextHero {
      font-size: 31px !important;
      right: 46.67%;
    }
  }

  @media #{$large-only} {
    .smallTxtHero {
      top: 8%;
    }

    .largeTextHero {
      font-size: 50px;
      line-height: 60px;
      right: 40%;
    }

    /*
    .slick-dots {
      left: 16.5%;

      & li {
        margin: 0 7px;
      }
    }
*/
    .carauselOrderNow {
      top: 56%;
    }

    .CircularProgressbar-text {
      font-size: 40px !important;
    }
  }

  @media #{$breakpoint-below-tablet} {
    .smallTxtHero {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      top: 19px;
    }

    .largeTextHero {
      font-size: 35px;
      line-height: 35px;
      letter-spacing: 0.5px;
    }

    .carausel-text-span {
      top: 20px;
    }
    /*
        .slick-dots {
          bottom: 4% !important;
          left: 9vw;
        }
    */
    .carauselOrderNow {
      margin-top: 10px;
    }

    .CircularProgressbar {
      height: 20px;
      width: 20px !important;
      margin-top: -12px;
      margin-right: 10px;
      @media #{$breakpoint-samsung-galaxy-tab-s7}{
        height: 13px;
        width: 13px !important;
        margin-top: -17px;
      }

      & .CircularProgressbar-text {
        display: none;
      }
    }
    .slick-active {
      top: -2px;
    }
  }

  @media #{$mobile-only} {
    .iGyjNW {
      font-weight: 400 !important;
    }
    .carausel-text-span {
      top: 10px !important;
    }

    .smallTxtHero {
      font-size: 16px;
      line-height: 20px;
      left: 30px;
      right: 33.67%;
      top: 49px;
      bottom: 72.4%;
    }

    .largeTextHero {
      font-size: 20px;
      line-height: 24px;
      left: 30px;
      right: 32.67%;
      top: 31.6%;
      bottom: 49.2%;
      width: 48%;
    }

    .carauselOrderNow {
      margin-top: 20px;
      align-items: center;
      padding: 10px 40px;
      left: 30px;
      right: 50.87%;
      font-family: $National2Test;
      font-weight: 500;
    }

    .carausel-paging {
      height: 10px;
      width: 10px;
      margin-top: 7px;
    }

    .CircularProgressbar {
      height: 7px;
      width: 7px !important;
      margin-top: -7px;
    }

    .slick-dots {
      //left: 30px;
      //bottom: 19px !important;

      & li {
        width: 10px !important;
        margin-right: 16px !important;
      }

      & .carausel-paging {
        height: 10px;
        width: 10px;
      }
    }
    .slick-active {
      top: 0px;
    }
  }

  .progress-circle {
    -moz-border-radius: 50px / 50px;
    -webkit-border-radius: 50px 50px;
    border-radius: 50px / 50px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);

    & .format-paging-no {
      transform: rotate(-45deg);
      font-size: 12px;
      position: absolute;
      left: 20%;
      top: 12%;
    }
  }

  .white-color.carausel-paging {
    border: 1px solid $white;
    top: 0;
    position: relative;

    @media #{$breakpoint-below-tablet} {
      background: $Inactive-Gray;
    }

    & .carausel-pagingNo {
      color: $white;
    }
  }

  .black-color.carausel-paging {
    position: relative;
    top: 0;

    & .carausel-pagingNo {
      color: $Brand-Black;
    }
  }

  .marketing-hero {
    position: relative;
    height: 396px;
    min-width: 100%;
    background: #f8f7f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$breakpoint-below-tablet} {
      height: 250px;
    }

    @media #{$medium-only} {
      height: 350px;
    }

    @media #{$mobile-only} {
      height: 250px;
    }
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast-container {
    top: 451px;

    @media #{$breakpoint-below-tablet} {
      top: 55% !important;
      width: 75% !important;
      margin: 0 12.5% !important;
    }
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast--dark {
    background: $Brand-Black;
    color: $white;
  }

  .sign-in-toast {
    width: 298px;
    top: 451px;
    padding: 0 !important;

    & .img-text-div {
      text-align: $center;
    }

    & .text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      font-family: $National2Regular;
      margin-left: 5px;
      @media #{$breakpoint-below-iphoneX} {
        font-size: 10px;
      }
    }
  }
  .carausel-image {
    //width: 100%;
    //max-height: 631px;
    width: auto;
    height: auto;
    position: absolute;
    left: 47%;
    top:0;
    @media #{$breakpoint-above-destop-wide}{
      top:7%;
    }
    @media #{$breakpoint-desktop-small}{
      width: 50%;
      height: auto;
      position: absolute;
      left: 48%;
      top: 22%;
    }
    @media #{$breakpoint-below-tablet}{
      width: 50%;
      height: auto;
      position: absolute;
      left: 48%;
      top: 0;
    }
    @media #{$mobile-only}{
      width: auto;
      height: auto;
      margin: auto;
      left:0;
      position: relative;
    }
  }
  .carausel-video {
    width: 100%;
    height: 600px;
    object-fit: cover;
    display: block;
    @media #{$mobile-only} {
      height: auto;
    }
  }

  .marketing-hero-img-div {
    cursor: pointer;
    position: relative;
    // height: 396px;
    // min-width: 100%;
    // margin-top: 30px;
    background: #f8f7f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .removePointer {
    cursor: default;
    position: relative;
    background: #f8f7f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .strip-Icon {
    @media #{$breakpoint-below-tablet} {
      margin-left: 43%;
    }

    @media #{$breakpoint-tablet-only} {
      margin-left: 46%;
    }
  }

  .strip-icon-img {
    width: 56px;
    height: 34px;
    @media #{$mobile-only} {
      width: 39px;
      height: 20px;
    }
  }

  .marketing-hero-greeing {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 60%;
    }
  }

  .greeting-heading {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 58px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-top: 60.71px;
    margin-bottom: 30px;
    display: flex;
    // flex-direction: column;
    position: absolute;
    top: 5%;
    z-index: 1;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 1px;
      display: block;
      position: inherit;
      margin-bottom: 0;
      margin-top: 23.71px;
    }
  }

  .market-hero-description {
    //position: absolute;
    //left: 16%;
    //right: 50.83%;
    //top: 26%;
    //bottom: 84.85%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media #{$mobile-only}{
     padding-left: 30px;
    }
    @media #{$breakpoint-tablet-only}{
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
    .color-white {
      color: $white;
    }
    .color-black {
      color: $Brand-Black;
    }
  }
  .strip-continer {
    position: absolute;
    left: 16.5%;
    right: 50.83%;
    @media #{$mobile-only},
    #{$breakpoint-below-tablet} {
      position: inherit;
      left: 0;
      right: 0;
    }
  }
    .marketHero-loader-aus {
      height: 63px;
      width: 100%;
    }
  .smallTxtHero-aus {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: $uppercase;
    color: $Brand-Black;
    margin-bottom: 20px;
  }
  .largeTextHero-aus {
    width: 580px;
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 74px;
    line-height: 78px;
    letter-spacing: 2px;
    text-transform: $uppercase;
    color: $Brand-Black;
  }

  .carauselOrderNow-aus {
    flex-direction: row;
    justify-content: $center;
    align-items: $center;
    margin: 30px 0px;
    //border-radius: 200px;
    font-family: $National2Regular;
    font-weight: $font-weight-500;
    //padding: 10px 40px;
    width:16.5rem;
  }
  .carauselOrderNow-NoteLine {
    font-size: 14px;
    font-family: $National2Regular;
    font-weight: 400;
    color: black;
    margin-bottom: 40px;
    // padding-left: 10px;
    line-height: 24px;
    width: 70%;
    @media #{$mobile-only}{
      margin-bottom: 20px;
      height: 48px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  //.slick-dots {
  //left: 16.5%;
  //bottom: 11% !important;
  //width: auto !important;
  // margin: 0 10px !important;
  //}

  @media #{$mobile-only} {
    .largeTextHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 100% !important;
    }
    .smallTxtHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 8px !important;
    }
    .carauselOrderNow-aus {
      margin-top: 20px;
    }
    .market-hero-description-signin {
      //top: 52% !important;
      @media only screen and (min-width: 400px) and (max-width: 450px) {
        //top: 50.5% !important;
      }
      .carauselOrderNow-NoteLine {
        margin-top: 25px;
      }
    }
    /*
    .market-hero-description {
      right: 3%;
      top: 40%;
      left: 8.5%;
    }
    .slick-dots {
      left: 8.5%;
      bottom: 14.5% !important;
      width: auto !important;
    }

    */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 94%;
    }
    .marketHero-loader-aus {
      height: 40px;
      width: 100%;
    }
    .gPqaVe {
      font-weight: 400 !important;
    }
    //.carauselOrderNow-aus {
    //padding: 12px 40px;
    //}
    .greeting-heading {
      margin-top: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 60%;
      left: 20%;
      @media #{$mobile-only}{
        margin-top: 25px;
      }
    }
  }
  @media #{$breakpoint-above-destop-wide} {
    .strip-continer {
      left: 12.1% !important;
    }
    /*
    .market-hero-description {
      right: 3%;
      top: 34%;
      left: 12.1%;
    }
    */
    .largeTextHero-aus {
      min-width: 580px;
      font-style: normal;
      font-weight: bold;
      font-size: 74px;
      line-height: 78px;
      letter-spacing: 2px;
      text-transform: uppercase;
      // margin-bottom: 1%;
    }
    .smallTxtHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 2%;
    }
    // .carauselOrderNow-aus {
    // margin-top: 1.9%;
    // }

    //.slick-dots {
    //left: 12.1%;
    //bottom: 11% !important;
    //width: auto !important;
    //}

    .carauselOrderNow-NoteLine {
      padding-left: 0;
      // width: 18%;
      margin-bottom: 40px;
      // padding-left: 10px;
      // width: 70%;
      // margin-top: 6%;
      font-size: 14px;
      font-family: $National2Regular;
      font-weight: 400;
      color: black;
    }
  }
  @media only screen and (min-width: 1500px) and (max-width: 1725px) {
    /*
    .market-hero-description {
      right: 3%;
      top: 40%;
      left: 15.3%;
    }
    */
    .smallTxtHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 2%;
    }
    .carauselOrderNow-aus {
      margin-top: 20px;
    }
    /*
        .slick-dots {
          left: 15.3%;
          bottom: 14% !important;
          width: auto !important;
        }
        */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 18%;
      // margin-top: 5%;
      font-size: 14px;
      font-family: $National2Regular;
      font-weight: $font-weight-500;
      color: black;
    }
    .strip-continer {
      left: 15.3%;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1499px) {
    /*
    .slick-dots {
      left: 16%;
      bottom: 13% !important;
      width: auto !important;
    }
    */
    .marketHero-loader-aus {
      height: 63px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1299px) {
    /*
    .slick-dots {
      left: 16%;
      bottom: 17%;
      width: auto !important;
    }
    */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 75%;
      // margin-top: 13%;
      font-size: 14px;
      font-family: $National2Regular;
      font-weight: $font-weight-500;
      color: black;
    }
    .marketHero-loader-aus {
      height: 63px;
      width: 100%;
    }
    /*
    .market-hero-description {
      top: 23%;
    }
    */
  }
  @media only screen and (min-width: 1100px) and (max-width: 1199px) {
    /*
    .market-hero-description {
      top: 22%;
    }
    */
    .marketHero-loader-aus {
      height: 63px;
      width: 100%;
    }
    /*
    .slick-dots {
      left: 16%;
      bottom: 18% !important;
      width: auto !important;
    }
    */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 75%;
      // margin-top: 16%;
      font-size: 14px;
      font-family: $National2Regular;
      font-weight: $font-weight-500;
      color: black;
    }
  }
  @media only screen and (min-width: 1021px) and (max-width: 1099px) {
    .strip-continer {
      left: 12.5% !important;
    }
    /*
    .market-hero-description {
      top: 12%;
      left: 12.5%;
    }
    */
    .marketHero-loader-aus {
      height: 80px;
      width: 100%;
    }
    /*
    .slick-dots {
      left: 12.5%;
      bottom: 13.5%;
      width: auto !important;
    }
    */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 75%;
      // width: 75%;
      font-size: 14px;
      font-family: $National2Regular;
      font-weight: $font-weight-500;
      color: black;
    }
  }
  @media #{$breakpoint-tablet-only} {
    .largeTextHero-aus {
      width: 50%;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .smallTxtHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .carauselOrderNow-aus {
      margin-top: 20px;
    }
    /*
    .market-hero-description {
      right: 3%;
      top: 11%;
      left: 10%;
    }
    .market-hero-description-signin {
      top: 23% !important;
    }
    .slick-dots {
      left: 10%;
      bottom: 15% !important;
      width: auto !important;
    }

    */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 94%;
      margin-top: 25px;
    }
    .marketHero-loader-aus {
      height: 30px;
      width: 100%;
    }
  }
  @media only screen and (min-width: 450px) and (max-width: 650px) {
    .largeTextHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 100% !important;
    }
    .smallTxtHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .carauselOrderNow-aus {
      margin-top: 20px;
    }
    /*
    .market-hero-description {
      right: 3%;
      top: 56%;
      left: 10%;
    }
    .slick-dots {
      left: 10%;
      bottom: 13% !important;
      width: auto !important;
    }

    */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 75%;
    }
    .marketHero-loader-aus {
      height: 52px;
      width: 100%;
    }
    .gPqaVe {
      font-weight: 400 !important;
    }
  }
  @media only screen and (min-width: 400px) and (max-width: 450px) {
    .largeTextHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 100% !important;
    }
    .smallTxtHero-aus {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    .carauselOrderNow-aus {
      margin-top: 20px;
    }
    /*
    .market-hero-description {
      right: 3%;
      top: 40%;
      left: 8%;
    }
    */
    .marketHero-loader-aus {
      height: 60px;
      width: 100%;
    }
    /*
    .slick-dots {
      left: 8%;
      bottom: 20% !important;
    }
    */
    .carauselOrderNow-NoteLine {
      padding-left: 0;
      width: 75%;
    }
    .gPqaVe {
      font-weight: 400 !important;
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 2560px) {
    .strip-continer {
      left: 16%;
    }
    .strip-Icon {
      margin-top: -3px;
    }
    /*
    .market-hero-description {
      top: 33%;
    }
    */
    .carausel-pagingNo {
      top: 4px !important
    }
    .slick-dots li {
      width: 40px !important;
      height: 40px !important;
      margin: 0 15px 0 0 !important;
    }
    .CircularProgressbar {
      height: 40px;
      width: 40px !important;
      .CircularProgressbar-path {
        stroke-width: 4px !important;
      }
    }
    .carausel-paging {
      height: 40px;
      width: 40px;
    }
    .white-color.carausel-paging {
      border: 2px solid $Dark-Gray !important;
      & .carausel-pagingNo {
        color: $Dark-Gray;
      }
    }
    .black-color.carausel-paging {
      border: 2px solid $white;

      & .carausel-pagingNo {
        color: $white;
      }
    }
    //.carauselOrderNow-aus {
    //padding: 12px 40px;
    //}
    .greeting-heading {
      margin-top: 46px;
      width: 580px;
    }
    .carauselOrderNow-NoteLine {
      min-width: 580px !important;
    }
    .CircularProgressbar-text {
      font-weight: 400;
      font-size: 25px !important;
    }

    .market-hero-description-signin {
      //top: 23.5% !important;
      .largeTextHero-aus {
        font-size: 64px;
        line-height: 68px;
        letter-spacing: 1px;
      }
      .carauselOrderNow-aus {
        margin-top: 25px;
      }
      .carauselOrderNow-NoteLine {
        margin-top: 25px;
      }
    }
  }
  @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
    @media (orientation: landscape) {
      /*
      .market-hero-description {
        top: 32% !important;
      }
      */
      .largeTextHero-aus {
        width: 40%;
        font-size: 48px;
        line-height: 46px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      .marketHero-loader-aus {
        height: 70px;
        width: 100%;
      }
      /*
      .slick-dots {
        left: 12.5%;
        bottom: 11.5% !important;
        width: auto !important;
      }
      */
      .carausel-pagingNo {
        top: 5px;
      }
      /*
      .slick-dots li {
        width: 40px !important;
        height: 40px !important;
        margin: 0 15px 0 0 !important;
      }
      */
      .CircularProgressbar {
        height: 40px;
        width: 40px !important;
        .CircularProgressbar-path {
          stroke-width: 4px !important;
        }
      }
      .carausel-paging {
        height: 40px;
        width: 40px;
      }
      .white-color.carausel-paging {
        border: 2px solid $Dark-Gray !important;
        & .carausel-pagingNo {
          color: $Dark-Gray;
        }
      }
      .black-color.carausel-paging {
        border: 2px solid $white;

        & .carausel-pagingNo {
          color: $white;
        }
      }
      //.carauselOrderNow-aus {
      //  padding: 12px 40px;
      //}
      .greeting-heading {
        margin-top: 46px;
      }
      .carauselOrderNow-NoteLine {
        width: 100%;
      }
    }
  }
  @media #{$breakpoint-below-tablet} {
    .white-color.carausel-paging {
      background: $Dark-Gray;
    }
    .carausel-paging {
      background-color: $Inactive-Gray;
      opacity: 1;
    }
  }

  @media #{$breakpoint-ipad-pro-12-landscape} {
    @media (orientation: landscape) {
      /*
      .market-hero-description {
        top: 32% !important;
      }
      */
      .strip-continer {
        left: 16%;
      }
      .strip-Icon {
        margin-top: -3px;
      }
      //.carauselOrderNow-aus {
      //  padding: 12px 40px;
      //}
      .greeting-heading {
        margin-top: 46px;
        width: 580px;
      }
      .white-color.carausel-paging {
        border: 2px solid $Dark-Gray !important;
        & .carausel-pagingNo {
          color: $Dark-Gray;
        }
      }
      .black-color.carausel-paging {
        border: 2px solid $white;

        & .carausel-pagingNo {
          color: $white;
        }
      }
      /*
      .slick-dots li {
        width: 40px !important;
        height: 40px !important;
        margin: 0 15px 0 0 !important;
      }
      */
      .CircularProgressbar {
        height: 40px;
        width: 40px !important;
        .CircularProgressbar-path {
          stroke-width: 4px !important;
        }
      }
      .carausel-paging {
        height: 40px;
        width: 40px;
      }
      /*
      .market-hero-description-signin {
        top: 23% !important;
      }
      */
      .carauselOrderNow-NoteLine {
        width: 100%;
      }
      .CircularProgressbar-text {
        font-weight: 400;
        font-size: 25px !important;
      }
    }
  }
  /*
  @media #{$breakpoint-iphone-12-pro-landscape} {
    @media (orientation: landscape) {
      .slick-dots {
        bottom: 20% !important;
      }
    }
  }
  */
  @media #{$breakpoint-samsung-galaxy-s7-tab} {
    .largeTextHero-aus {
      width: 40%;
      font-size: 48px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    /*
    .slick-dots {
      bottom: 18% !important;
    }
    */
  }
  @media #{$breakpoint-ipad-pro-11} {
    /*
    .slick-dots {
      bottom: 18% !important;
    }
    */
    .carauselOrderNow-NoteLine {
      width: 100%;
    }
    .CircularProgressbar-text {
      font-weight: 400;
      font-size: 25px !important;
    }
  }
  @media #{$breakpoint-ipad-pro-11-oreintation-landscape} {
    .largeTextHero-aus {
      width: 95%;
      font-size: 48px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    /*
    .slick-dots {
      bottom: 22% !important;
    }
    */
    /*
    .slick-dots li {
      width: 40px !important;
      height: 40px !important;
      margin: 0 15px 0 0 !important;
    }
    */
    .CircularProgressbar {
      height: 40px;
      width: 40px !important;
      .CircularProgressbar-path {
        stroke-width: 4px !important;
      }
    }
    .carausel-paging {
      height: 40px;
      width: 40px;
    }
    .white-color.carausel-paging {
      border: 2px solid $Dark-Gray !important;
      & .carausel-pagingNo {
        color: $Dark-Gray;
      }
    }
    .black-color.carausel-paging {
      border: 2px solid $white;

      & .carausel-pagingNo {
        color: $white;
      }
    }
    .carauselOrderNow-NoteLine {
      width: 100%;
    }
    .CircularProgressbar-text {
      font-weight: 400;
      font-size: 25px !important;
    }
  }
  @media #{$breakpoint-samsung-s21plus} {
    /*
    .slick-dots {
      bottom: 15.5% !important;
    }
    */
  }
  @media #{$breakpoint-samsung-s20} {
    /*
    .market-hero-description {
      top: 34%;
    }
    .slick-dots {
      bottom: 19% !important;
    }
    */
  }
  @media #{$breakpoint-googlepixel-5} {
    /*
    .slick-dots {
      bottom: 16.5% !important;
    }
    */
  }
  @media #{$breakpoint-iphone13} {
    /*
    .slick-dots {
      bottom: 17.5% !important;
    }
    */
  }
  @media #{$breakpoint-iphone-12-pro-portrait} {
    /*
    .slick-dots {
      bottom: 17.5% !important;
    }
    */
  }
  @media #{$breakpoint-iphone-11-pro-portrait} {
    /*
    .slick-dots {
      bottom: 15% !important;
    }
    */
  }
  @media #{$breakpoint-desktop-small} {
    .largeTextHero-aus {
      width: 40%;
      font-size: 48px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    /*
    .slick-dots {
      bottom: 15.5% !important;
    }
    */
    /*
    .slick-dots li {
      width: 40px !important;
      height: 40px !important;
      margin: 0 15px 0 0 !important;
    }
    */
    .CircularProgressbar {
      height: 40px;
      width: 40px !important;
      .CircularProgressbar-path {
        stroke-width: 4px !important;
      }
    }
    .carausel-paging {
      height: 40px;
      width: 40px;
    }
    .white-color.carausel-paging {
      border: 2px solid $Dark-Gray !important;
      & .carausel-pagingNo {
        color: $Dark-Gray;
      }
    }
    .black-color.carausel-paging {
      border: 2px solid $white;

      & .carausel-pagingNo {
        color: $white;
      }
    }
    .carauselOrderNow-NoteLine {
      min-width: 580px;
    }
    .CircularProgressbar-text {
      font-weight: 400;
      font-size: 25px !important;
    }
    /*
    .market-hero-description {
      top: 25%;
    }
    .market-hero-description-signin {
      top: 30% !important;
    }
    */
    .carauselOrderNow-aus {
      margin-bottom: 30px;
    }
    .marketHero-loader-aus {
      height: 55px;
      width: 100%;
    }
  }
  @media #{$breakpoint-desktop-small-2}{
    .CircularProgressbar {
      height: 40px;
      width: 40px !important;
      .CircularProgressbar-path {
        stroke-width: 4px !important;
      }
    }
    .CircularProgressbar-text {
      font-weight: 400;
      font-size: 25px !important;
    }
    .largeTextHero-aus {
      width: 95%;
      font-size: 48px;
      line-height: 46px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .white-color.carausel-paging {
      border: 1px solid $Dark-Gray !important;
      & .carausel-pagingNo {
        color: $Dark-Gray;
      }
    }
    .black-color.carausel-paging {
      border: 1px solid $white;

      & .carausel-pagingNo {
        color: $white;
      }
    }
  }
  @media #{$breakpoint-samsung-galaxy-tab-s7} {
    .grid-container{
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .slick-dots{
      left: 4%;
    }
    .transperant-img-dots{
   .slick-dots {
      bottom: 4.7rem;
    }
    .carauselOrderNow-NoteLine{
      width: 40%;
      height:75px;
      margin-bottom: 0;
    }
    }
  }
  @media #{$breakpoint-ipad-landscape}{
    .transperant-img-dots{
     .slick-dots {
        bottom: 4.7rem;
      }
      .carauselOrderNow-NoteLine{
        width: 40%;
        height:75px;
        margin-bottom: 0;
      }
      }
  }
  @media #{$breakpoint-above-desktop}{
    .strip-continer {
      left: 15% !important;
    }
    .transperant-img{
      .carausel-image{
        width: 30% !important;
      }
    }
    .market-hero-description{
      // top:27%;
      // left: 15%;
    }
    .slick-dots {
      // left: 15%;
      // bottom: 16%;
    }
  }
  .transperant-img{
    background: #F8F7F5;
  }

}


